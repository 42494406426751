<template>
  <div>
    <v-card>
      <v-row class="mx-2 pt-2">
        <v-col>
          <h3>Daftar Workflow</h3>
        </v-col>
      </v-row>
      <v-divider class="my-4" />
      <div class="px-4 pb-4">
        <v-row class="mb-1">
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="selectedSort"
              :items="sortOptions"
              item-text="label"
              item-value="id"
              label="Sort"
              outlined
              dense
              class="w-25"
              return-object
              hide-details="auto"
              @change="changeSortWorkflow"
            >
              <template v-slot:item="{ item }">
                <div class="d-flex align-center justify-space-between flex-grow-1">
                  <div class="font-weight-bold">
                    <v-icon
                      class="me-1"
                      size="26"
                    >
                      {{ item.icon }}
                    </v-icon>
                    <span class="font-weight-semibold"> {{ item.label }}</span>
                  </div>
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="workflowFilter.search"
              outlined
              dense
              label="Cari user"
              class="w-100"
              hide-details="auto"
              @change="searchWorkflow"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="selectedStatus"
              :items="statusOptions"
              item-text="label"
              item-value="id"
              label="Status"
              outlined
              dense
              class="w-25"
              return-object
              hide-details="auto"
              @change="changeStatusWorkflow"
            >
              <template v-slot:item="{ item }">
                <div class="d-flex align-center justify-space-between flex-grow-1">
                  <div class="font-weight-bold">
                    <v-icon
                      class="me-1"
                      size="26"
                    >
                      {{ item.icon }}
                    </v-icon>
                    <span class="font-weight-semibold"> {{ item.label }}</span>
                  </div>
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-chip
          v-if="selectedSort.id !== 1 || workflowFilter.search || selectedStatus.id !== 1"
          class="mb-4"
          small
          close
          color="error"
          @click:close="resetFilter"
        >
          Reset Filter
        </v-chip>
      </div>
      <v-data-table
        :headers="headerWorkflow"
        :items="userWorkflowList"
        :items-per-page="10"
        item-key="id"
        disable-sort
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
          'items-per-page-text': 'Jumlah data',
        }"
        :server-items-length="userWorkflowCount"
        mobile-breakpoint="0"
        :loading="loadingUserWorkflowList"
        :options.sync="workflowPagination"
      >
        <template
          v-slot:[`item.name`]="{ item }"
        >
          <router-link
            :to="{
              name: 'admin-workflow-detail',
              params: {
                id: item.id,
              },
            }"
          >
            <span>{{ item.name }}</span>
          </router-link>
        </template>
        <template
          v-slot:[`item.created_at`]="{ item }"
        >
          <span>{{ formatDate(item.created_at) }}</span>
        </template>
        <template v-slot:[`item.workspace`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              size="40px"
              color="primary"
              class="v-avatar-light-bg primary--text"
            >
              <span>
                {{ avatarText(item.workspace.name) }}
              </span>
            </v-avatar>
            <span class="text-subtitle-2 ms-2">{{ item.workspace.name }}</span>
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            label
            :color="item.is_disabled ? 'secondary' : 'primary'"
          >
            {{ item.is_disabled ? 'Nonaktif' : 'Aktif' }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import useUser from '@/composables/useUser'
import { formatDate, avatarText } from '@core/utils/filter'
import { mdiSortAlphabeticalAscending, mdiSortAlphabeticalDescending } from '@mdi/js'

export default {
  setup() {
    const {
      fetchUserWorkflowList, userWorkflowCount, loadingUserWorkflowList, userWorkflowList,
    } = useUser()

    const workflowPagination = ref({
      page: 1,
      itemsPerPage: 10,
    })

    const workflowFilter = ref({ sort: 'id', order: 'ASC', search: '' })

    const selectedSort = ref({
      id: 1,
      label: 'Nama Workflow',
      field: 'name',
      type: 'ASC',
      icon: mdiSortAlphabeticalAscending,
    })
    const selectedStatus = ref({ id: 1, label: 'Semua', value: null })

    const fetchWorkflow = () => {
      fetchUserWorkflowList(
        {
          limit: workflowPagination.value.itemsPerPage ? workflowPagination.value.itemsPerPage : 10,
          offset: workflowPagination.value.page && (workflowPagination.value.page - 1) * workflowPagination.value.itemsPerPage,
        },
        null,
        {
          search: workflowFilter.value.search,
          is_disabled: selectedStatus.value.value,
          sort: selectedSort.value.field,
          order: selectedSort.value.type,
        },
      )
    }

    const headerWorkflow = ref([
      {
        text: 'Nama Workflow',
        value: 'name',
      },
      {
        text: 'Tanggal Dibuat',
        value: 'created_at',
      },
      {
        text: 'Workspace',
        value: 'workspace',
      },
      {
        text: 'Status',
        value: 'status',
      },
    ])

    onMounted(() => {
      fetchWorkflow()
    })

    const sortOptions = ref([
      {
        id: 1,
        label: 'Nama Workflow',
        field: 'name',
        type: 'ASC',
        icon: mdiSortAlphabeticalAscending,
      },
      {
        id: 2,
        label: 'Nama Workflow',
        field: 'name',
        type: 'DESC',
        icon: mdiSortAlphabeticalDescending,
      },
      {
        id: 3,
        label: 'Tanggal Dibuat',
        field: 'created_at',
        type: 'ASC',
        icon: mdiSortAlphabeticalAscending,
      },
      {
        id: 4,
        label: 'Tanggal Dibuat',
        field: 'created_at',
        type: 'DESC',
        icon: mdiSortAlphabeticalDescending,
      },
    ])

    const statusOptions = ref([
      { id: 1, label: 'Semua', value: null },
      { id: 2, label: 'Enabled', value: false },
      { id: 3, label: 'Disabled', value: true },
    ])

    const changeSortWorkflow = val => {
      workflowFilter.value.sort = val.field
      workflowFilter.value.order = val.type
      fetchWorkflow()
    }

    const searchWorkflow = () => {
      fetchWorkflow()
    }

    const changeStatusWorkflow = () => {
      fetchWorkflow()
    }

    watch(workflowPagination, () => {
      fetchWorkflow()
    })

    const resetFilter = () => {
      workflowFilter.value = { sort: 'id', order: 'ASC', search: '' }
      selectedStatus.value = { id: 1, label: 'Semua', value: null }
      selectedSort.value = {
        id: 1,
        label: 'Nama Workflow',
        field: 'name',
        type: 'ASC',
        icon: mdiSortAlphabeticalAscending,
      }

      fetchWorkflow()
    }

    return {
      userWorkflowCount,
      loadingUserWorkflowList,
      userWorkflowList,
      headerWorkflow,
      formatDate,
      workflowPagination,
      avatarText,
      sortOptions,
      changeSortWorkflow,
      workflowFilter,
      selectedSort,
      searchWorkflow,
      statusOptions,
      changeStatusWorkflow,
      selectedStatus,
      resetFilter,
    }
  },
}
</script>
