import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VRow,{staticClass:"mx-2 pt-2"},[_c(VCol,[_c('h3',[_vm._v("Daftar Workflow")])])],1),_c(VDivider,{staticClass:"my-4"}),_c('div',{staticClass:"px-4 pb-4"},[_c(VRow,{staticClass:"mb-1"},[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VSelect,{staticClass:"w-25",attrs:{"items":_vm.sortOptions,"item-text":"label","item-value":"id","label":"Sort","outlined":"","dense":"","return-object":"","hide-details":"auto"},on:{"change":_vm.changeSortWorkflow},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-space-between flex-grow-1"},[_c('div',{staticClass:"font-weight-bold"},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"26"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(item.label))])],1)])]}}]),model:{value:(_vm.selectedSort),callback:function ($$v) {_vm.selectedSort=$$v},expression:"selectedSort"}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VTextField,{staticClass:"w-100",attrs:{"outlined":"","dense":"","label":"Cari user","hide-details":"auto"},on:{"change":_vm.searchWorkflow},model:{value:(_vm.workflowFilter.search),callback:function ($$v) {_vm.$set(_vm.workflowFilter, "search", $$v)},expression:"workflowFilter.search"}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VSelect,{staticClass:"w-25",attrs:{"items":_vm.statusOptions,"item-text":"label","item-value":"id","label":"Status","outlined":"","dense":"","return-object":"","hide-details":"auto"},on:{"change":_vm.changeStatusWorkflow},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-space-between flex-grow-1"},[_c('div',{staticClass:"font-weight-bold"},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"26"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(item.label))])],1)])]}}]),model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1)],1),(_vm.selectedSort.id !== 1 || _vm.workflowFilter.search || _vm.selectedStatus.id !== 1)?_c(VChip,{staticClass:"mb-4",attrs:{"small":"","close":"","color":"error"},on:{"click:close":_vm.resetFilter}},[_vm._v(" Reset Filter ")]):_vm._e()],1),_c(VDataTable,{attrs:{"headers":_vm.headerWorkflow,"items":_vm.userWorkflowList,"items-per-page":10,"item-key":"id","disable-sort":"","footer-props":{
        'items-per-page-options': [10, 25, 50],
        'items-per-page-text': 'Jumlah data',
      },"server-items-length":_vm.userWorkflowCount,"mobile-breakpoint":"0","loading":_vm.loadingUserWorkflowList,"options":_vm.workflowPagination},on:{"update:options":function($event){_vm.workflowPagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":{
            name: 'admin-workflow-detail',
            params: {
              id: item.id,
            },
          }}},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}},{key:"item.workspace",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"40px","color":"primary"}},[_c('span',[_vm._v(" "+_vm._s(_vm.avatarText(item.workspace.name))+" ")])]),_c('span',{staticClass:"text-subtitle-2 ms-2"},[_vm._v(_vm._s(item.workspace.name))])],1)]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c(VChip,{attrs:{"label":"","color":item.is_disabled ? 'secondary' : 'primary'}},[_vm._v(" "+_vm._s(item.is_disabled ? 'Nonaktif' : 'Aktif')+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }